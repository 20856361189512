<template>
  <v-row class="py-16">
    <nav
      class="
        flex
        items-center
        text-center
        justify-between
        flex-wrap
        gap-2
        py-4
        top-0
        w-full
        z-10
        navi
      "
    >
      <v-row>
        <v-app-bar color="  px-8 primary" dark>
          <v-app-bar-nav-icon
            class="d-sm-flex d-md-flex d-lg-none"
            clipped-right
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>

          <!-- <v-container> -->
          <v-row
            align="center"
            class="d-none d-lg-flex d-xl-flex"
            justify="center"
          >
            <v-col cols="11">
              <v-tabs
                v-model="active_tab"
                fixed-tabs
                background-color="primary"
                dark
                centered
              >
                <v-tab
                  class="text-caption"
                  v-for="i in tabsNav"
                  :key="i.index"
                  :to="i.url"
                >
                  <div v-if="i.index == 0" class="mt-2">
                    <v-img
                      max-height="50"
                      max-width="160"
                      contain
                      src="@/assets/logo/dxcv.png"
                    ></v-img>
                  </div>
                  <div v-if="i.index != 0">
                    {{ i.tab }}
                  </div>
                </v-tab>
                <!-- <a prepend-icon="mdi-information-outline" href="www.google.com"></a> -->
              </v-tabs>
            </v-col>
            <v-col cols="1">
              <v-btn
                class="mt-3"
                color="primary"
                prepend-icon="mdi-cog-outline"
                :href="manualUrl"
                ><v-icon dark>mdi-information-outline</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <!-- </v-container> -->
        </v-app-bar>

        <v-dialog v-model="drawer">
          <v-list nav dense>
            <v-list-item-group
              v-model="group"
              class="text-center justify-center"
              active-class="deep-purple--text text--accent-4"
            >
              <v-list-item v-for="i in tabsNav" :key="i.index" :to="i.url">
                <v-list-item-title>
                  <div
                    v-if="i.index == 0"
                    class="mt-2 justify-center text-center"
                  >
                    <v-img
                      v-if="i.index == 0"
                      max-height="40"
                      max-width="130"
                      contain
                      src="@/assets/logo/dxcv.png"
                    ></v-img>
                    {{ i.tab }}
                  </div>
                  <div v-if="i.index != 0">
                    {{ i.tab }}
                  </div></v-list-item-title
                >
              </v-list-item>
              <v-btn class="mt-3" color="primary" :href="manualUrl"
                ><v-icon dark>mdi-information-outline</v-icon></v-btn
              >
            </v-list-item-group>
          </v-list>
        </v-dialog>
      </v-row>
    </nav>
  </v-row>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    drawer: false,
    group: null,
    manualUrl: "",
    active_tab: "",
    tabsNavSave: [
      {
        tab: "",
        name: "",
        url: "",
        index: "0",
      },
      {
        tab: "Záznamy stretnutí",
        name: "TableOfMeetings",
        url: "/",
        index: "1",
      },
      {
        tab: "Záznamy záverov",
        name: "TableOfTasks",
        url: "/task",
        index: "2",
      },
      {
        tab: "Fulltextové vyhľadávanie",
        name: "FullTextSearch",
        url: "/search",
        index: "3",
      },
      {
        tab: "Používatelia",
        name: "",
        url: "/admin",
        index: "4",
      },
      {
        tab: "Odhlásiť sa",
        url: "/login",
        index: "5",
      },
    ],
    tabsNav: [
      {
        tab: "",
        name: "",
        url: "",
        index: "0",
      },
      {
        tab: "Záznamy stretnutí",
        // tab: "XXX",
        name: "TableOfMeetings",
        url: "/",
        index: "1",
      },
      {
        tab: "Záznamy záverov",
        name: "TableOfTasks",
        url: "/task",
        index: "2",
      },
      {
        tab: "Fulltextové vyhľadávanie",
        name: "FullTextSearch",
        url: "/search",
        index: "3",
      },
      // {
      //   tab: "Offline",
      //   name: "Offline",
      //   url: "/offline",
      //   index: "4",
      // },
      {
        tab: "Používatelia",
        name: "",
        url: "/admin",
        index: "4",
      },
      {
        tab: "Odhlásiť sa",
        url: "/login",
        index: "5",
      },
    ],
  }),
  computed: {
    ...mapGetters(["getIsAdmin"]),
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  mounted() {
    this.actualLoading = 0;
    this.loadData();
  },
  methods: {
    loadData() {
      this.manualUrl = process.env.VUE_APP_URL + process.env.VUE_APP_MANUAL_URL;
      // if (this.getIsAdmin == "true") {
      // this.tabsNav[0].url = "/admin";
      // } else this.tabsNav[0].url = "/";
      if (window.matchMedia("(display-mode: standalone)").matches) {
        if (this.tabsNav.length == 6)
          this.tabsNav.push({
            tab: "Offline",
            name: "Offline",
            url: "/offline",
            index: "6",
          });
      } else {
        if (this.tabsNav.lengthv == 6) this.tabsNav = this.tabsNavSave;
      }
    },
  },
};
</script>


<style scoped>
.navi {
  top: -10px;

  position: fixed;
  z-index: 1000;
  width: 100%;
}
</style>










